/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextField,
  PhoneNumberField
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { API } from "aws-amplify";
import { createCompany } from "../graphql/mutations";
export default function CompanyCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    street: "",
    city: "",
    additionalAddressLine: "",
    zip: "",
    country: "",
    phone: "",
    dialCode: "+49",
    email: "",
  };
  const [name, setName] = React.useState(initialValues.name);
  const [street, setStreet] = React.useState(initialValues.street);
  const [city, setCity] = React.useState(initialValues.city);
  const [additionalAddressLine, setAdditionalAddressLine] = React.useState(
    initialValues.additionalAddressLine
  );
  const [zip, setZip] = React.useState(initialValues.zip);
  const [country, setCountry] = React.useState(initialValues.country);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [dialCode, setDialCode] = React.useState(initialValues.dialCode);
  const [email, setEmail] = React.useState(initialValues.email);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setName(initialValues.name);
    setStreet(initialValues.street);
    setCity(initialValues.city);
    setAdditionalAddressLine(initialValues.additionalAddressLine);
    setZip(initialValues.zip);
    setCountry(initialValues.country);
    setPhone(initialValues.phone);
    setDialCode(initialValues.dialCode);
    setEmail(initialValues.email);
    setErrors({});
  };
  const validations = {
    name: [{ type: "Required" }],
    street: [{ type: "Required" }],
    city: [{ type: "Required" }],
    additionalAddressLine: [],
    zip: [{ type: "Required" }],
    country: [{ type: "Required" }],
    phone: [{ type: "Required" }, { type: "Phone" }],
    email: [{ type: "Required" }, { type: "Email" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          street,
          city,
          additionalAddressLine,
          zip,
          country,
          phone,
          dialCode,
          email,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await API.graphql({
            query: createCompany.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "CompanyCreateForm")}
      {...rest}
    >
      <TextField
        label="Firmenname"
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              street,
              city,
              additionalAddressLine,
              zip,
              country,
              phone,
              email,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Zusätzliche Adresszeile"
        isRequired={false}
        isReadOnly={false}
        value={additionalAddressLine}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              street,
              city,
              additionalAddressLine: value,
              zip,
              country,
              phone,
              email,
              alias,
            };
            const result = onChange(modelFields);
            value = result?.additionalAddressLine ?? value;
          }
          if (errors.additionalAddressLine?.hasError) {
            runValidationTasks("additionalAddressLine", value);
          }
          setAdditionalAddressLine(value);
        }}
        onBlur={() =>
          runValidationTasks("additionalAddressLine", additionalAddressLine)
        }
        errorMessage={errors.additionalAddressLine?.errorMessage}
        hasError={errors.additionalAddressLine?.hasError}
        {...getOverrideProps(overrides, "additionalAddressLine")}
      ></TextField>
      <TextField
        label="Straße"
        isRequired={true}
        isReadOnly={false}
        value={street}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              street: value,
              city,
              additionalAddressLine,
              zip,
              country,
              phone,
              email,
            };
            const result = onChange(modelFields);
            value = result?.street ?? value;
          }
          if (errors.street?.hasError) {
            runValidationTasks("street", value);
          }
          setStreet(value);
        }}
        onBlur={() => runValidationTasks("street", street)}
        errorMessage={errors.street?.errorMessage}
        hasError={errors.street?.hasError}
        {...getOverrideProps(overrides, "street")}
      ></TextField>
      <TextField
        label="Stadt"
        isRequired={true}
        isReadOnly={false}
        value={city}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              street,
              city: value,
              additionalAddressLine,
              zip,
              country,
              phone,
              email,
            };
            const result = onChange(modelFields);
            value = result?.city ?? value;
          }
          if (errors.city?.hasError) {
            runValidationTasks("city", value);
          }
          setCity(value);
        }}
        onBlur={() => runValidationTasks("city", city)}
        errorMessage={errors.city?.errorMessage}
        hasError={errors.city?.hasError}
        {...getOverrideProps(overrides, "city")}
      ></TextField>
      <TextField
        label="PLZ"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={zip}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              street,
              city,
              additionalAddressLine,
              zip: value,
              country,
              phone,
              email,
            };
            const result = onChange(modelFields);
            value = result?.zip ?? value;
          }
          if (errors.zip?.hasError) {
            runValidationTasks("zip", value);
          }
          setZip(value);
        }}
        onBlur={() => runValidationTasks("zip", zip)}
        errorMessage={errors.zip?.errorMessage}
        hasError={errors.zip?.hasError}
        {...getOverrideProps(overrides, "zip")}
      ></TextField>
      <SelectField
        label="Land"
        placeholder="Bitte wählen"
        isDisabled={false}
        value={country}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              street,
              city,
              additionalAddressLine,
              zip,
              country: value,
              phone,
              email,
              alias,
            };
            const result = onChange(modelFields);
            value = result?.country ?? value;
          }
          if (errors.country?.hasError) {
            runValidationTasks("country", value);
          }
          setCountry(value);
        }}
        onBlur={() => runValidationTasks("country", country)}
        errorMessage={errors.country?.errorMessage}
        hasError={errors.country?.hasError}
        {...getOverrideProps(overrides, "country")}
      >
        <option
          children="Germany"
          value="Germany"
          {...getOverrideProps(overrides, "countryoption0")}
        ></option>
        <option
          children="Austria"
          value="Austria"
          {...getOverrideProps(overrides, "countryoption1")}
        ></option>
        <option
          children="Netherlands"
          value="Netherlands"
          {...getOverrideProps(overrides, "countryoption2")}
        ></option>
        <option
          children="Luxembourg"
          value="Luxembourg"
          {...getOverrideProps(overrides, "countryoption3")}
        ></option>
        <option
          children="Belgium"
          value="Belgium"
          {...getOverrideProps(overrides, "countryoption4")}
        ></option>
        <option
          children="France"
          value="France"
          {...getOverrideProps(overrides, "countryoption5")}
        ></option>
        <option
          children="Denmark"
          value="Denmark"
          {...getOverrideProps(overrides, "countryoption6")}
        ></option>
        <option
          children="Sweden"
          value="Sweden"
          {...getOverrideProps(overrides, "countryoption7")}
        ></option>
      </SelectField>
      <PhoneNumberField
        label="Telefonnummer Ihrer Organisation"
        isRequired={true}
        isReadOnly={false}
        defaultDialCode="+49"
        value={phone}
        onDialCodeChange={(e) => {
          let { value } = e.target;
          setDialCode(value);
        }}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              street,
              city,
              additionalAddressLine,
              zip,
              country,
              phone: value,
              email,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></PhoneNumberField>
      <TextField
        label="Kontaktemailadresse Ihrer Organisation (z.B. kontakt@example.com)"
        isRequired={true}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              street,
              city,
              additionalAddressLine,
              zip,
              country,
              phone,
              email: value,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Speichern"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid >
  );
}
