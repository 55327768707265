/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      street
      city
      additionalAddressLine
      zip
      country
      phone
      email
      alias
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      plan {
        id
        name
        description
        monthlyPrice
        price
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      companyAlias
      startDate
      endDate
      count
      monthlyPrice
      price
      createdAt
      updatedAt
      orderPlanId
      orderCompanyId
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        plan {
          id
          name
          description
          monthlyPrice
          price
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          street
          city
          additionalAddressLine
          zip
          country
          phone
          email
          alias
          createdAt
          updatedAt
          __typename
        }
        companyAlias
        startDate
        endDate
        count
        monthlyPrice
        price
        createdAt
        updatedAt
        orderPlanId
        orderCompanyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const employeesByCompanyAliasOrder = /* GraphQL */ `
  query EmployeesByCompanyAliasOrder(
    $companyAlias: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByCompanyAliasOrder(
      companyAlias: $companyAlias
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        plan {
          id
          name
          description
          monthlyPrice
          price
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          street
          city
          additionalAddressLine
          zip
          country
          phone
          email
          alias
          createdAt
          updatedAt
          __typename
        }
        companyAlias
        startDate
        endDate
        count
        monthlyPrice
        price
        createdAt
        updatedAt
        orderPlanId
        orderCompanyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      description
      monthlyPrice
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        monthlyPrice
        price
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      companyAlias
      members {
        items {
          id
          firstName
          lastName
          email
          companyAlias
          disabled
          createdAt
          updatedAt
          groupMembersId
          employeeCompanyId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        companyAlias
        members {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      firstName
      lastName
      email
      group {
        id
        name
        companyAlias
        members {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      companyAlias
      disabled
      createdAt
      updatedAt
      groupMembersId
      employeeCompanyId
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        group {
          id
          name
          companyAlias
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          street
          city
          additionalAddressLine
          zip
          country
          phone
          email
          alias
          createdAt
          updatedAt
          __typename
        }
        companyAlias
        disabled
        createdAt
        updatedAt
        groupMembersId
        employeeCompanyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const employeesByCompanyAlias = /* GraphQL */ `
  query EmployeesByCompanyAlias(
    $companyAlias: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByCompanyAlias(
      companyAlias: $companyAlias
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        group {
          id
          name
          companyAlias
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          street
          city
          additionalAddressLine
          zip
          country
          phone
          email
          alias
          createdAt
          updatedAt
          __typename
        }
        companyAlias
        disabled
        createdAt
        updatedAt
        groupMembersId
        employeeCompanyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
