import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
    Button,
    Flex,
    Heading,
    Loader,
    Message,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Text,
    useTheme,
} from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import { Auth, I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';
import { toast, Zoom } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateRight, faTrashCan, faPen, faPlus, faX } from '@fortawesome/free-solid-svg-icons'

import { listCompanies, listEmployees } from './graphql/queries';
import { createEmployee, updateEmployee, deleteEmployee } from './graphql/mutations';

import { fetchUserGroups, getNumberOfValidLicenses, getNumberOfActiveEmployees } from './Common'

import EmployeeCreateForm from './ui-components/EmployeeCreateForm'
import EmployeeUpdateForm from './ui-components/EmployeeUpdateForm'

I18n.putVocabularies(translations);
I18n.setLanguage('de');

const EmployeeTab = () => {
    const [employees, setEmployees] = useState([]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [usedLicenses, setUsedLicenses] = useState(0);
    const [numberOfValidLicenses, setNumberOfValidLicenses] = useState(0);
    const [licensedEmployeeIds, setLicensedEmployeeIds] = useState([]);
    const [companyAlias, setCompanyAlias] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState({
        heading: "",
        content: "",
        type: "info",
    });

    const { tokens } = useTheme();

    const fetchEmployees = async () => {
        try {
            const employeeData = await API.graphql(graphqlOperation(listEmployees));
            const employees = employeeData.data.listEmployees.items;
            setEmployees(employees);

            const licensedEmployeeIds = employees.map((employee) => {
                if (!employee.disabled) {
                    return employee.id;
                }
                return undefined;
            }).filter(e => e !== undefined);;
            setLicensedEmployeeIds(licensedEmployeeIds);
        } catch (err) {
            console.error("Error fetching employees:", err);
        }
    };

    const calculateUsedLicenses = async () => {
        const numberOfValidLicenses = await getNumberOfValidLicenses();
        const numberOfEmployees = await getNumberOfActiveEmployees();

        setNumberOfValidLicenses(numberOfValidLicenses);

        if (numberOfValidLicenses === 0) {
            setUsedLicenses(100);
        } else {
            setUsedLicenses(Math.round((numberOfEmployees / numberOfValidLicenses) * 100));
        }
    }

    const fetchCompanyAlias = async () => {
        const cognitoGroups = await fetchUserGroups();
        const cognitoGroup = cognitoGroups[0];
        setCompanyAlias(cognitoGroup);
    }

    useEffect(() => {
        calculateUsedLicenses();
        fetchEmployees();
        fetchCompanyAlias();
    }, []);

    const handleDeleteEmployee = async (employee) => {
        try {
            await API.graphql(graphqlOperation(deleteEmployee, { input: { id: employee.id } }));
            await fetchEmployees();
            await calculateUsedLicenses();
        } catch (err) {
            console.error("Error deleting employee:", err);
        }
    };

    const handleResetPassword = async (employee) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;

            const payload = {
                username: `${employee.firstName}.${employee.lastName}@${companyAlias}`,
            };

            const headers = {
                Authorization: token,
                'Content-Type': 'application/json'
            };
            await API.post('PostersApi', 'resetEmployeePassword', {
                body: payload,
                headers: headers,
            });

            setShowMessage(true);
            setMessage({
                heading: "Erfolg",
                content: `Passwort von ${employee.firstName} ${employee.lastName} wurde zurückgesetzt. Der Mitarbeiter kann über die "Erster Login"-Option in der App erneut ein Passwort setzen.`,
                type: "success",
            });
            setTimeout(() => {
                setShowMessage(false);
            }, 6000);
        } catch (err) {
            console.error("Error deleting employee:", err);
        }
    };

    return (
        <div className="employee-tab">
            <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            >
                <Heading level={3}>Mitarbeiter</Heading>
                <Flex direction="row" justifyContent="space-evenly" alignItems="center" gap="2rem">
                    <Flex direction="row">
                        <Text fontWeight={800}>Lizenznutzung:</Text>
                        {numberOfValidLicenses > 0 ? <Loader variation="linear" percentage={usedLicenses} isDeterminate width="14em" /> : <Text>Keine aktiven Lizenzen</Text>}
                    </Flex>
                    <Button marginRight="1em" onClick={() => setCreateModalOpen(true)} isDisabled={usedLicenses === 100}>Mitarbeiter hinzufügen&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                </Flex>
            </Flex>
            <Modal isOpen={isCreateModalOpen}
                onRequestClose={() => setCreateModalOpen(false)}
                contentLabel="Erstellung eines Mitarbeiters"
                style={createModalStyles}
                ariaHideApp={false}>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                >
                    <Heading level={4}>Mitarbeiter hinzufügen</Heading>
                    <Button onClick={() => setCreateModalOpen(false)}><FontAwesomeIcon icon={faX} /></Button>
                </Flex>

                <EmployeeCreateForm
                    onSubmit={async (fields) => {
                        const updatedFields = {}
                        Object.keys(fields).forEach(key => {
                            if (typeof fields[key] === 'string') {
                                updatedFields[key] = fields[key].trim()
                            } else {
                                updatedFields[key] = fields[key]
                            }
                        })

                        const cognitoGroups = await fetchUserGroups();
                        const cognitoGroup = cognitoGroups[0];
                        const companyData = await API.graphql(graphqlOperation(listCompanies));
                        const companies = companyData.data.listCompanies.items;
                        let company;
                        for (const c of companies) {
                            if (c.alias === cognitoGroup) {
                                company = c;
                                break;
                            }
                        }

                        updatedFields.companyAlias = cognitoGroup;
                        updatedFields.employeeCompanyId = company.id;
                        updatedFields.groupMembersId = updatedFields.group?.id || ""

                        if (!updatedFields.email) {
                            delete updatedFields.email;
                        }
                        if (!updatedFields.groupMembersId) {
                            delete updatedFields.groupMembersId;
                        }
                        delete updatedFields.group;

                        await API.graphql(
                            graphqlOperation(createEmployee, { input: updatedFields })
                        );

                        setCreateModalOpen(false);
                        await fetchEmployees();
                        await calculateUsedLicenses();
                    }}
                    onValidate={{
                        disabled: async (value, validationResponse) => {
                            const numberOfValidLicenses = await getNumberOfValidLicenses();
                            const numberOfEmployees = await getNumberOfActiveEmployees();
                            const usedLicenses = Math.round((numberOfEmployees / numberOfValidLicenses) * 100);
                            if (!value && usedLicenses === 100) {
                                return {
                                    hasError: true,
                                    errorMessage: 'Keine freien Lizenzen verfügbar'
                                };
                            }
                            return validationResponse;
                        }
                    }}
                />
            </Modal>
            <Modal isOpen={isUpdateModalOpen}
                onRequestClose={() => setUpdateModalOpen(false)}
                contentLabel="Bearbeitung eines Mitarbeiters"
                style={updateModalStyles}
                ariaHideApp={false}>
                <Flex
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                >
                    <Button onClick={() => setUpdateModalOpen(false)}><FontAwesomeIcon icon={faX} /></Button>
                </Flex>

                <EmployeeUpdateForm
                    employee={selectedEmployee && {
                        id: selectedEmployee.id,
                        firstName: selectedEmployee.firstName,
                        lastName: selectedEmployee.lastName,
                        email: selectedEmployee.email || "",
                        group: selectedEmployee.group,
                        disabled: selectedEmployee.disabled,
                    }}
                    onSubmit={(fields) => {
                        const updateEmployeeInput = {
                            id: selectedEmployee.id,
                            firstName: fields.firstName,
                            lastName: fields.lastName,
                            email: fields.email ? fields.email : null,
                            groupMembersId: fields.group ? fields.group.id : null,
                            disabled: fields.disabled,
                        }

                        API.graphql(
                            graphqlOperation(updateEmployee, { input: updateEmployeeInput })
                        ).then(() => {
                            setUpdateModalOpen(false);
                            setSelectedEmployee(null);
                            fetchEmployees();
                            calculateUsedLicenses();
                        });
                    }}
                    onValidate={{
                        disabled: async (value, validationResponse) => {
                            const numberOfValidLicenses = await getNumberOfValidLicenses();
                            const numberOfEmployees = await getNumberOfActiveEmployees();
                            const usedLicenses = Math.round((numberOfEmployees / numberOfValidLicenses) * 100);

                            if (licensedEmployeeIds.includes(selectedEmployee.id)) {
                                return validationResponse;
                            }

                            if (!value || numberOfValidLicenses === 0 || usedLicenses === 100) {
                                return {
                                    hasError: true,
                                    errorMessage: 'Keine freien Lizenzen verfügbar'
                                };
                            }
                            return validationResponse;
                        }
                    }}
                />
            </Modal>
            <Table marginTop={tokens.space.xl} variation="striped">
                <TableHead>
                    <TableRow>
                        <TableCell as="th">Vorname</TableCell>
                        <TableCell as="th">Nachname</TableCell>
                        <TableCell as="th">Gruppe</TableCell>
                        <TableCell as="th">Status</TableCell>
                        <TableCell as="th">Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {employees.map(employee =>
                        <TableRow key={employee.id} >
                            <TableCell>{employee.firstName}</TableCell>
                            <TableCell>{employee.lastName}</TableCell>
                            <TableCell>{employee.group && employee.group.name}</TableCell>
                            <TableCell>{employee.disabled ? "Deaktiviert" : "Aktiviert"}</TableCell>
                            <TableCell>
                                <Button onClick={() => { setSelectedEmployee(employee); setUpdateModalOpen(true); }}>Bearbeiten&nbsp;<FontAwesomeIcon icon={faPen} /></Button>
                                <div style={{ display: 'inline-block', width: '10px' }}></div>
                                <Button onClick={() => handleResetPassword(employee)}>Passwort zurücksetzen&nbsp;<FontAwesomeIcon icon={faArrowRotateRight} /></Button>
                                <div style={{ display: 'inline-block', width: '10px' }}></div>
                                <Button onClick={() => handleDeleteEmployee(employee)}>Löschen&nbsp;<FontAwesomeIcon icon={faTrashCan} /></Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {showMessage && <Message
                variation="filled"
                colorTheme={message.type}
                heading={message.heading}
                style={messageStyle}>
                {message.content}
            </Message>
            }
        </div >
    );
};

const createModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
};

const updateModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
};

const messageStyle = {
    position: "absolute",
    bottom: "50px",
    right: "100px",
    "zIndex": "1050",
}

export default EmployeeTab;
