import { API, Auth, graphqlOperation } from 'aws-amplify';

import moment from 'moment'

import { listEmployees, listOrders } from './graphql/queries';

export const fetchUserGroups = async () => {
    try {
        // Retrieve the current authenticated user
        const user = await Auth.currentAuthenticatedUser();

        // Get the idToken from the user object
        const idToken = user.signInUserSession.idToken.jwtToken;

        // Parse the token to get the payload
        const payload = JSON.parse(atob(idToken.split('.')[1]));

        // Extract the cognito:groups from the payload
        const userGroups = payload['cognito:groups'] || [];

        return userGroups;
    } catch (error) {
        console.error('Error getting user groups:', error);
    }
}

export const getNumberOfValidLicenses = async () => {

    const orderData = await API.graphql(graphqlOperation(listOrders));
    const orders = orderData.data.listOrders.items;

    let count = 0;
    for (const order of orders) {
        if (moment().isBetween(order.startDate, order.endDate) || moment().isSame(order.startDate) || moment().isSame(order.endDate)) {
            count += order.count
        }
    }
    return count;
}

export const getNumberOfActiveEmployees = async () => {

    const employeeData = await API.graphql(graphqlOperation(listEmployees));
    const employees = employeeData.data.listEmployees.items;
    const result = employees.filter((employee) =>
        employee.disabled === false
    );
    let count = result.length;

    return count;
}