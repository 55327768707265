import React, { useState, useEffect } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
    Button,
    Flex,
    Heading,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    useTheme,
    Message,
} from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPen, faPlus, faX, faFileExport, faList, faFileImport } from '@fortawesome/free-solid-svg-icons'

import { listGroups } from './graphql/queries';
import { createGroup, updateGroup, deleteGroup } from './graphql/mutations';

import GroupCreateForm from './ui-components/GroupCreateForm'
import GroupUpdateForm from './ui-components/GroupUpdateForm'
import ConfirmationModal from './ConfirmationModal'
import PosterList from './PosterList'

import { saveAs } from 'file-saver';
import { json2csv } from 'json-2-csv'
import moment from 'moment-timezone'

I18n.putVocabularies(translations);
I18n.setLanguage('de');

const GroupTab = () => {
    const { tokens } = useTheme();

    const [groups, setGroups] = useState([]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState("");
    const [groupToDelete, setGroupToDelete] = useState(null);
    const [isPosterListModalOpen, setIsPosterListModalOpen] = useState(false);
    const [isMessageModalOpen, setMessageModelOpen] = useState(false);

    const [globalExportRunning, setGlobalExportRunning] = useState(false);
    const [exportForGroupRunning, setExportForGroupRunning] = useState(null);
    const [importForGroupRunning, setImportForGroupRunning] = useState(null);

    const [message, setMessage] = useState({
        heading: "",
        content: "",
        type: "info",
    });

    const handleCloseConfirmation = () => {
        setConfirmationModalOpen(false);
    }

    const handleConfirmConfirmation = async () => {
        if (groupToDelete) {
            try {
                await API.graphql(graphqlOperation(deleteGroup, { input: { id: groupToDelete.id } }));
                await fetchGroups();
            } catch (err) {
                console.error("Error deleting group:", err);
            }
        }
        setGroupToDelete(null);
        setConfirmationModalOpen(false);
    }

    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchUserGroups = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const idToken = user.signInUserSession.idToken.jwtToken;
            const payload = JSON.parse(atob(idToken.split('.')[1]));
            const userGroups = payload['cognito:groups'] || [];
            return userGroups;
        } catch (error) {
            console.error('Error getting user groups:', error);
        }
    }

    const fetchGroups = async () => {
        try {
            const groupData = await API.graphql(graphqlOperation(listGroups));
            setGroups(groupData.data.listGroups.items);
        } catch (err) {
            console.error("Error fetching groups:", err);
        }
    };

    const handleDeleteGroup = async (group) => {
        setConfirmationModalText("Gruppe inklusive aller Plakate der Gruppe wirklich löschen?");
        setGroupToDelete(group);
        setConfirmationModalOpen(true);
    };

    const handleExportPosters = async (groupsForExport) => {
        try {
            if (groupsForExport.length === 1 && groups.length === 1) {
                setGlobalExportRunning(true);
                setExportForGroupRunning(groupsForExport[0].id);
            } else if (groupsForExport.length === 1) {
                setExportForGroupRunning(groupsForExport[0].id);
            } else {
                setGlobalExportRunning(true);
            }

            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;

            const cognitoGroups = await fetchUserGroups();
            const cognitoGroup = cognitoGroups[0];

            const groupNames = groupsForExport.map(group => `${group.name}@${cognitoGroup}`);
            const payload = {
                groupNames: groupNames,
            };

            const headers = {
                Authorization: token,
                'Content-Type': 'application/json'
            };
            const result = await API.post('PostersApi', 'posters/csv', {
                body: payload,
                headers: headers,
            });
            const csvData = await json2csv(result.result, { delimiter: { field: ";" }, excelBOM: true });
            const blobData = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

            const currentTime = moment().tz('Europe/Berlin').format('MM-DD-YYYY_HH-mm-ss');
            let fileName;
            if (groupsForExport.length === 1) {
                fileName = `WIMP_Export_${groupsForExport[0].name}_${currentTime}.csv`;
            } else {
                fileName = `WIMP_Export_${currentTime}.csv`;
            }

            saveAs(blobData, fileName);
        } catch (err) {
            console.log(err);
        }

        setExportForGroupRunning(null);
        setGlobalExportRunning(false);
    };

    const handleFileImport = async (event, group) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const fileContent = e.target.result;
                try {
                    setImportForGroupRunning(group.id);

                    const user = await Auth.currentAuthenticatedUser();
                    const token = user.signInUserSession.idToken.jwtToken;

                    const cognitoGroups = await fetchUserGroups();
                    const cognitoGroup = cognitoGroups[0];

                    const payload = {
                        csvData: fileContent,
                        groupName: `${group.name}@${cognitoGroup}`,
                    };

                    const headers = {
                        Authorization: token,
                        'Content-Type': 'application/json'
                    };

                    await API.post('PostersApi', 'posters/importCsv', {
                        body: payload,
                        headers: headers,
                    });

                    setMessage({
                        heading: "Erfolg",
                        content: "Import erfolgreich",
                        type: "success",
                    });
                    setMessageModelOpen(true);
                    setTimeout(() => {
                        setMessageModelOpen(false);
                    }, 3000);
                    setImportForGroupRunning(null);
                } catch (err) {
                    console.error(err);
                    setMessage({
                        heading: "Fehler",
                        content: "Import fehlgeschlagen. Bitte an Support wenden.",
                        type: "error",
                    });
                    setMessageModelOpen(true);
                    setTimeout(() => {
                        setMessageModelOpen(false);
                    }, 3000);
                    setImportForGroupRunning(null);
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <div className="group-tab">
            <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            >
                <Heading level={3}>Gruppen</Heading>
                <Flex>
                    <Button marginRight="1em" isLoading={globalExportRunning} loadingText='Export läuft...' onClick={() => handleExportPosters(groups)} disabled={groups.length === 0}>Alle Plakate exportieren&nbsp;<FontAwesomeIcon icon={faFileExport} /></Button>
                    <Button marginRight="1em" onClick={() => setCreateModalOpen(true)}>Gruppe erstellen&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                </Flex>
            </Flex>
            <Modal isOpen={isCreateModalOpen}
                onRequestClose={() => setCreateModalOpen(false)}
                contentLabel="Erstellung einer Gruppe"
                style={modalStyle}
                ariaHideApp={false}>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                >
                    <Heading level={4}>Gruppe erstellen</Heading>
                    <Button onClick={() => setCreateModalOpen(false)}><FontAwesomeIcon icon={faX} /></Button>
                </Flex>

                <GroupCreateForm
                    onSubmit={async (fields) => {

                        const cognitoGroups = await fetchUserGroups();
                        const cognitoGroup = cognitoGroups[0];

                        const createGroupInput = {
                            name: fields.name,
                            companyAlias: cognitoGroup,
                        }
                        await API.graphql(
                            graphqlOperation(createGroup, { input: createGroupInput })
                        );

                        await fetchGroups();
                        setCreateModalOpen(false);
                    }}
                />
            </Modal>
            <Modal isOpen={isUpdateModalOpen}
                onRequestClose={() => setUpdateModalOpen(false)}
                contentLabel="Bearbeitung einer Gruppe"
                style={modalStyle}
                ariaHideApp={false}>
                <Flex
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                >
                    <Button onClick={() => setUpdateModalOpen(false)}><FontAwesomeIcon icon={faX} /></Button>
                </Flex>

                <GroupUpdateForm
                    group={selectedGroup}
                    onSubmit={async (fields) => {
                        const updateGroupInput = {
                            id: selectedGroup.id,
                            name: fields.name,
                        }
                        await API.graphql(
                            graphqlOperation(updateGroup, { input: updateGroupInput })
                        );

                        await fetchGroups();
                        setUpdateModalOpen(false);
                    }}
                />
            </Modal>
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                onCancel={handleCloseConfirmation}
                onConfirm={handleConfirmConfirmation}
                message={confirmationModalText}
            />
            <Modal isOpen={isPosterListModalOpen}
                onRequestClose={() => setIsPosterListModalOpen(false)}
                contentLabel="Plakatliste"
                style={largeModalStyle}
                ariaHideApp={false}>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                >
                    <Heading level={3}>Plakatliste</Heading>
                    <Flex>
                        <Button onClick={() => setIsPosterListModalOpen(false)}><FontAwesomeIcon icon={faX} /></Button>
                    </Flex>
                </Flex>
                <PosterList groupId={selectedGroup?.id} />
            </Modal>
            <Table marginTop={tokens.space.xl} variation="striped">
                <TableHead>
                    <TableRow>
                        <TableCell as="th">Name</TableCell>
                        <TableCell as="th">Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map(group => (
                        <TableRow key={group.id}>
                            <TableCell>{group.name}</TableCell>
                            <TableCell>
                                <Flex>
                                    <Button onClick={() => { setSelectedGroup(group); setIsPosterListModalOpen(true); }}>Plakatliste öffnen&nbsp;<FontAwesomeIcon icon={faList} /></Button>
                                    <Button isLoading={exportForGroupRunning === group.id} loadingText='Export läuft...' onClick={() => handleExportPosters([group])}>Plakate exportieren&nbsp;<FontAwesomeIcon icon={faFileExport} /></Button>
                                    <Button onClick={() => { setSelectedGroup(group); setUpdateModalOpen(true); }}>Bearbeiten&nbsp;<FontAwesomeIcon icon={faPen} /></Button>
                                    <Button onClick={() => handleDeleteGroup(group)}>Löschen&nbsp;<FontAwesomeIcon icon={faTrashCan} /></Button>
                                    <input
                                        type="file"
                                        accept=".csv"
                                        style={{ display: 'none' }}
                                        onChange={(event) => handleFileImport(event, group)}
                                        id={`fileInput-${group.id}`}
                                    />
                                    <Button isLoading={importForGroupRunning === group.id} loadingText='Import läuft...' onClick={() => document.getElementById(`fileInput-${group.id}`).click()}>
                                        Plakate importieren&nbsp;<FontAwesomeIcon icon={faFileImport} />
                                    </Button>
                                </Flex>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Modal isOpen={isMessageModalOpen}
                onRequestClose={() => setMessageModelOpen(false)}
                contentLabel="Profil"
                style={messageModalStyles}
                ariaHideApp={false}>
                <Message
                    variation="filled"
                    colorTheme={message.type}
                    heading={message.heading}>
                    {message.content}
                </Message>
            </Modal>
        </div>
    );
};

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
};

const largeModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '95%',
        maxHeight: '95%',
        overflowY: 'auto',
    },
};

const messageModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
};

export default GroupTab;