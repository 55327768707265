import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';

import { API, Auth, graphqlOperation } from 'aws-amplify';
import { AccountSettings, Button, Flex, Heading, Image, Menu, MenuItem, Divider, Text, Message } from '@aws-amplify/ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'

import logo from './assets/icon.png';
import CompanyUpdateForm from './ui-components/CompanyUpdateForm'
import { listCompanies } from './graphql/queries';
import { updateCompany } from './graphql/mutations';

I18n.putVocabularies(translations);
I18n.setLanguage('de');

const TopBar = () => {
    const [isPasswordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
    const [isCompanyUpdateModalOpen, setCompanyUpdateModalOpen] = useState(false);
    const [isMessageModalOpen, setMessageModelOpen] = useState(false);
    const [message, setMessage] = useState({
        heading: "",
        content: "",
        type: "info",
    });
    const [userEmail, setUserEmail] = useState(null);
    const [company, setCompany] = useState(null);

    const fetchCompany = async () => {
        try {
            const companyData = await API.graphql(graphqlOperation(listCompanies));
            const company = companyData.data.listCompanies.items[0];
            setCompany(company);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const fetchUserEmail = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUserEmail(user.attributes.email);
            } catch (err) {
                console.log(err);
            }
        };
        fetchUserEmail();
        fetchCompany();
    }, []);

    const handleLogout = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const handlePasswordChangedSuccessfully = () => {
        setMessage({
            heading: "Erfolg",
            content: "Passwort wurde geändert",
            type: "success",
        });
        setMessageModelOpen(true);

        setPasswordChangeModalOpen(false);
        setTimeout(() => {
            setMessageModelOpen(false);
        }, 2500);
    }

    return (
        <Flex direction="row" justifyContent="space-between" alignItems="center" className="three-column-layout">
            <Flex flex="1" justifyContent="flex-start">
                <Image
                    alt="Logo"
                    src={logo}
                    objectFit="initial"
                    objectPosition="50% 50%"
                    backgroundColor="initial"
                    height="75%"
                    width="5em"
                    opacity="100%"
                    marginLeft="1em"
                />
            </Flex>
            <Flex flex="1" direction="column" justifyContent="center" alignItems="center">
                <Heading level={2}>Wo ist mein Plakat?</Heading>
                <Heading level={2}>Konsole</Heading>
            </Flex>
            <Flex flex="1" direction="column" justifyContent="flex-end">
                <Flex flex="1" direction="row" justifyContent="flex-end" marginRight={16}>
                    <Text fontWeight={800}>Alias für Login:</Text>
                    <Text>{company?.alias}</Text>
                </Flex>
                <Flex flex="1" direction="row" justifyContent="flex-end">
                    <Menu
                        trigger={
                            <Text variation="primary"
                                as="p"
                                lineHeight="1.5em"
                                fontWeight={550}
                                fontSize="1em"
                                fontStyle="normal"
                                textDecoration="underline"
                                style={{ cursor: 'pointer', marginRight: '1em' }}>{company && company.name} - {userEmail}</Text>
                        }>
                        <MenuItem onClick={() => setPasswordChangeModalOpen(true)}>Passwort ändern</MenuItem>
                        <MenuItem onClick={() => setCompanyUpdateModalOpen(true)}>
                            Anschrift ändern
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Flex>
            </Flex>
            <Modal isOpen={isPasswordChangeModalOpen}
                onRequestClose={() => setPasswordChangeModalOpen(false)}
                contentLabel="Profil"
                style={changePasswordModalStyles}
                ariaHideApp={false}>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                >
                    <Heading level={4}>Passwort ändern</Heading>
                    <Button onClick={() => setPasswordChangeModalOpen(false)}><FontAwesomeIcon icon={faX} /></Button>
                </Flex>
                <AccountSettings.ChangePassword onSuccess={handlePasswordChangedSuccessfully} />
            </Modal>
            <Modal isOpen={isCompanyUpdateModalOpen}
                onRequestClose={() => setCompanyUpdateModalOpen(false)}
                contentLabel="Profil"
                style={companyUpdateModalStyles}
                ariaHideApp={false}>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                >
                    <Heading level={4}>Anschrift ändern</Heading>
                    <Button onClick={() => setCompanyUpdateModalOpen(false)}><FontAwesomeIcon icon={faX} /></Button>
                </Flex>

                <CompanyUpdateForm
                    company={company && {
                        id: company.id,
                        name: company.name,
                        street: company.street,
                        city: company.city,
                        zip: company.zip,
                        additionalAddressLine: company.additionalAddressLine,
                        country: company.country ?? "",
                        phone: company.phone,
                        email: company.email
                    }}
                    onSubmit={async (fields) => {
                        const updateCompanyInput = {
                            id: company.id,
                            name: fields.name,
                            street: fields.street,
                            city: fields.city,
                            zip: fields.zip,
                            additionalAddressLine: fields.additionalAddressLine ?? "",
                            country: fields.country,
                            phone: fields.phone,
                            email: fields.email,
                        }

                        await API.graphql(
                            graphqlOperation(updateCompany, { input: updateCompanyInput })
                        );

                        setMessage({
                            heading: "Erfolg",
                            content: "Anschrift wurde geändert",
                            type: "success",
                        });
                        setMessageModelOpen(true);

                        setCompanyUpdateModalOpen(false);
                        setTimeout(() => {
                            setMessageModelOpen(false);
                        }, 2500);
                        fetchCompany();
                    }}
                />
            </Modal>
            <Modal isOpen={isMessageModalOpen}
                onRequestClose={() => setMessageModelOpen(false)}
                contentLabel="Profil"
                style={messageModalStyles}
                ariaHideApp={false}>
                <Message
                    variation="filled"
                    colorTheme={message.type}
                    heading={message.heading}>
                    {message.content}
                </Message>
            </Modal>
        </Flex>
    );
};

const changePasswordModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
};

const companyUpdateModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '90%',
        overflowY: 'auto',
    },
};

const messageModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
};

export default TopBar;
