import React, { useState, useEffect } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
    Button,
    Loader,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    useTheme,
} from '@aws-amplify/ui-react';
import { getGroup } from './graphql/queries';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'

const PosterList = ({ groupId }) => {
    const { tokens } = useTheme();

    const [posters, setPosters] = useState([]);
    const [group, setGroup] = useState();
    const [loading, setLoading] = useState(true);
    const [deleteForPosterRunning, setDeleteForPosterRunning] = useState(null);

    const fetchUserGroups = async () => {
        try {
            // Retrieve the current authenticated user
            const user = await Auth.currentAuthenticatedUser();

            // Get the idToken from the user object
            const idToken = user.signInUserSession.idToken.jwtToken;

            // Parse the token to get the payload
            const payload = JSON.parse(atob(idToken.split('.')[1]));

            // Extract the cognito:groups from the payload
            const userGroups = payload['cognito:groups'] || [];

            return userGroups;
        } catch (error) {
            console.error('Error getting user groups:', error);
        }
    }

    const fetchPosters = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const cognitoGroups = await fetchUserGroups();
        const cognitoGroup = cognitoGroups[0];

        const groupData = await API.graphql(graphqlOperation(getGroup, { id: groupId }));
        setGroup(groupData.data.getGroup);

        const payload = {
            groupNames: [
                `${groupData.data.getGroup.name}@${cognitoGroup}`,
            ],
        };

        const headers = {
            Authorization: token,
            'Content-Type': 'application/json'
        };
        const result = await API.post('PostersApi', 'posters', {
            body: payload,
            headers: headers,
        });
        setPosters(result.result);

        setLoading(false);
    }

    useEffect(() => {
        fetchPosters();
    }, []);

    const handleDeletePoster = async (posterId) => {
        if (posterId) {
            setLoading(true);
            setDeleteForPosterRunning(posterId);

            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const headers = {
                Authorization: token,
                'Content-Type': 'application/json'
            };
            await API.del('PostersApi', `posters/${posterId}`, {
                headers: headers,
            });
            await fetchPosters();

            setLoading(false);
            setDeleteForPosterRunning(null);
        }
    };

    return (
        <div>
            {loading ? <Loader variation="linear" /> : null}
            <Table marginTop={tokens.space.xl} variation="striped">
                <TableHead>
                    <TableRow>
                        <TableCell as="th">Straße</TableCell>
                        <TableCell as="th">Stadt</TableCell>
                        <TableCell as="th">Hängt</TableCell>
                        <TableCell as="th">Erstellt</TableCell>
                        <TableCell as="th">Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {posters.map(poster => (
                        <TableRow key={poster.objectId}>
                            <TableCell>{poster.street}</TableCell>
                            <TableCell>{poster.city}</TableCell>
                            <TableCell>{poster.present ? 'Ja' : 'Nein'}</TableCell>
                            <TableCell>{new Date(poster.createdAt).toLocaleString()}</TableCell>
                            <TableCell>
                                <Button isLoading={deleteForPosterRunning === poster.objectId} loadingText='Lösche...' onClick={() => handleDeletePoster(poster.objectId)}>Löschen&nbsp;<FontAwesomeIcon icon={faTrashCan} /></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default PosterList;
