import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify, I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';

import awsExports from './aws-exports';

const apiConfig = {
  "aws_cloud_logic_custom": [
    {
      "name": "PostersApi",
      "endpoint": "https://cj2s9xddp9.execute-api.eu-central-1.amazonaws.com/prod/",
      "region": "eu-central-1"
    }
  ]
};

// Create a new configuration object by merging awsExports and your API configuration
const amplifyConfig = {
  ...awsExports,
  ...apiConfig
};

Amplify.configure(amplifyConfig);

I18n.putVocabularies(translations);
I18n.setLanguage('de');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
