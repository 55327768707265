import './App.css';

import packageJson from '../package.json';

import React, { useEffect, useState } from 'react';

import { API, Auth } from 'aws-amplify';
import { Heading, Link, Message, Tabs, TabItem, Text, View, useTheme, Flex } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import TopBar from './TopBar';
import EmployeeTab from './EmployeeTab';
import GroupTab from './GroupTab';
import OrdersTab from './OrdersTab';

import FullScreenLoader from './FullScreenLoader';

import { createCompany } from './graphql/mutations';
import CreateCompanyForm from './ui-components/CompanyCreateForm'
import { fetchUserGroups } from './Common';

const AuthenticatedApp = () => {
    const { tokens } = useTheme();

    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState({
        heading: "",
        content: "",
        type: "info",
    });

    useEffect(() => {
        updateGroups();
    }, []);

    const updateGroups = async () => {
        const groups = await fetchUserGroups();
        setGroups(groups);
    };

    const deUmlaut = (value) => {
        value = value.toLowerCase();
        value = value.replace(/ä/g, 'ae');
        value = value.replace(/ö/g, 'oe');
        value = value.replace(/ü/g, 'ue');
        value = value.replace(/ß/g, 'ss');
        value = value.replace(/ /g, '-');
        value = value.replace(/\./g, '');
        value = value.replace(/,/g, '');
        value = value.replace(/\(/g, '');
        value = value.replace(/\)/g, '');
        return value;
    }

    const checkIfCompanyExists = async (companyAlias) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const payload = {
            companyAlias: companyAlias,
        };

        const headers = {
            Authorization: token,
            'Content-Type': 'application/json'
        };
        const result = await API.post('PostersApi', 'checkIfCompanyExists', {
            body: payload,
            headers: headers,
        });

        return result.companyExists;
    };

    return (
        <Flex direction="column" minHeight="100vh" className='App'>
            <FullScreenLoader isLoading={isLoading} />
            <Flex direction="column" flex="1" as="main">
                {groups.length > 0 ?
                    <>
                        <TopBar />
                        <Tabs spacing="equal" justifyContent="center">
                            <TabItem title="Mitarbeiter">
                                <EmployeeTab />
                            </TabItem>
                            <TabItem title="Gruppen">
                                <GroupTab />
                            </TabItem>
                            <TabItem title="Käufe">
                                <OrdersTab />
                            </TabItem>
                        </Tabs>
                    </>
                    :
                    <div style={formStyle}>
                        <Heading level={3}>Herzlich Willkommen!</Heading>
                        <Text>Bitte geben Sie Informationen zu Ihrer Organisation an.</Text>
                        <CreateCompanyForm
                            onSubmit={async (fields) => {
                                setIsLoading(true);
                                const generatedAlias = deUmlaut(fields.name.trim().toLowerCase());
                                let alias = generatedAlias;
                                let count = 1;

                                while (await checkIfCompanyExists(alias)) {
                                    alias = generatedAlias + count;
                                    count = count + 1;
                                }

                                try {
                                    await API.graphql({
                                        query: createCompany,
                                        variables: {
                                            input: {
                                                name: fields.name,
                                                street: fields.street,
                                                city: fields.city,
                                                zip: fields.zip,
                                                additionalAddressLine: fields.additionalAddressLine ?? "",
                                                country: fields.country,
                                                phone: `${fields.dialCode}${fields.phone}`,
                                                email: fields.email,
                                                alias: alias,
                                            }
                                        }
                                    });
                                } catch (response) {
                                    setIsLoading(false);
                                    setMessage({
                                        heading: "Fehler",
                                        content: response.errors[0].message,
                                        type: "error",
                                    });
                                    setShowMessage(true);
                                    await new Promise(resolve => setTimeout(resolve, 3000));
                                    setShowMessage(false);
                                    return;
                                }

                                await new Promise(resolve => setTimeout(resolve, 3000)); // Wait 3 seconds for finishing group update

                                const user = await Auth.currentAuthenticatedUser();
                                const token = user.signInUserSession.idToken.jwtToken;

                                const payload = {
                                    groupName: alias,
                                };

                                const headers = {
                                    Authorization: token,
                                    'Content-Type': 'application/json'
                                };

                                await API.post('PostersApi', 'addUserToGroup', {
                                    body: payload,
                                    headers: headers,
                                });

                                setMessage({
                                    heading: "Erfolg",
                                    content: "Daten erfolgreich hinterlegt. Bitte neu einloggen.",
                                    type: "success",
                                });
                                setShowMessage(true);

                                await new Promise(resolve => setTimeout(resolve, 3000));
                                setIsLoading(false);
                                await Auth.signOut();
                            }}
                        />
                    </div>
                }
                {showMessage && <Message
                    variation="filled"
                    colorTheme={message.type}
                    heading={message.heading}
                    style={messageStyle}>
                    {message.content}
                </Message>
                }
            </Flex>
            <View textAlign="center" padding={tokens.space.large} style={footerStyle} as="footer">
                <Link href="https://avaneo.io#impressum" color="#000000" isExternal={true}>
                    Impressum
                </Link>
                <Text>Version: {packageJson.version}</Text>
            </View>
        </Flex>
    );
}

const formStyle = {
    'textAlign': 'left',
    width: '50%',
    margin: '0 auto',
};

const messageStyle = {
    position: "absolute",
    bottom: "50px",
    right: "100px",
    "zIndex": "1050",
}

const footerStyle = {
    fontSize: "12px",
}

export default AuthenticatedApp;