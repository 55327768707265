/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      street
      city
      additionalAddressLine
      zip
      country
      phone
      email
      alias
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      street
      city
      additionalAddressLine
      zip
      country
      phone
      email
      alias
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      street
      city
      additionalAddressLine
      zip
      country
      phone
      email
      alias
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      plan {
        id
        name
        description
        monthlyPrice
        price
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      companyAlias
      startDate
      endDate
      count
      monthlyPrice
      price
      createdAt
      updatedAt
      orderPlanId
      orderCompanyId
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      plan {
        id
        name
        description
        monthlyPrice
        price
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      companyAlias
      startDate
      endDate
      count
      monthlyPrice
      price
      createdAt
      updatedAt
      orderPlanId
      orderCompanyId
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      plan {
        id
        name
        description
        monthlyPrice
        price
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      companyAlias
      startDate
      endDate
      count
      monthlyPrice
      price
      createdAt
      updatedAt
      orderPlanId
      orderCompanyId
      __typename
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      description
      monthlyPrice
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      description
      monthlyPrice
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      description
      monthlyPrice
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      companyAlias
      members {
        items {
          id
          firstName
          lastName
          email
          companyAlias
          disabled
          createdAt
          updatedAt
          groupMembersId
          employeeCompanyId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      companyAlias
      members {
        items {
          id
          firstName
          lastName
          email
          companyAlias
          disabled
          createdAt
          updatedAt
          groupMembersId
          employeeCompanyId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      companyAlias
      members {
        items {
          id
          firstName
          lastName
          email
          companyAlias
          disabled
          createdAt
          updatedAt
          groupMembersId
          employeeCompanyId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      group {
        id
        name
        companyAlias
        members {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      companyAlias
      disabled
      createdAt
      updatedAt
      groupMembersId
      employeeCompanyId
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      group {
        id
        name
        companyAlias
        members {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      companyAlias
      disabled
      createdAt
      updatedAt
      groupMembersId
      employeeCompanyId
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      group {
        id
        name
        companyAlias
        members {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        street
        city
        additionalAddressLine
        zip
        country
        phone
        email
        alias
        createdAt
        updatedAt
        __typename
      }
      companyAlias
      disabled
      createdAt
      updatedAt
      groupMembersId
      employeeCompanyId
      __typename
    }
  }
`;
