import React from 'react';
import { Button, Text, Flex } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

function ConfirmationModal({ isOpen, onCancel, onConfirm, message }) {
    return (
        <Modal isOpen={isOpen} style={modalStyles} ariaHideApp={false}>
            <Flex direction="column" gap="1.5rem" alignItems="center" padding="1.5rem">
                <Text>{message}</Text>
                <Flex gap="1rem">
                    <Button variation="primary" onClick={onConfirm}>Bestätigen</Button>
                    <Button variation="default" onClick={onCancel}>Abbrechen</Button>
                </Flex>
            </Flex>
        </Modal>
    );
}

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
};

export default ConfirmationModal;