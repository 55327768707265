import React from 'react';
import { Loader } from '@aws-amplify/ui-react';
import './FullScreenLoader.css';

const FullScreenLoader = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="fullscreen-loader">
            <Loader size="large" className="loader" />
        </div>
    );
};

export default FullScreenLoader;