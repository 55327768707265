import './App.css';

import packageJson from '../package.json';

import React from 'react';

import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';
import { Authenticator, Heading, Image, Link, Text, View, useTheme } from '@aws-amplify/ui-react';
import { BrowserRouter as Router } from 'react-router-dom';

import '@aws-amplify/ui-react/styles.css';
import AuthenticatedApp from './AuthenticatedApp';
import logo from './assets/icon.png';

I18n.putVocabularies(translations);
I18n.setLanguage('de');

I18n.putVocabularies({
  de: {
    'Given Name': 'Vorname',
    'Family Name': "Nachname",
    'Enter your Given Name': "Geben Sie Ihren Vornamen ein",
    'Enter your Family Name': "Geben Sie Ihren Nachnamen ein",
    "Your passwords must match": "Die Passwörter müssen übereinstimmen",
    "Password must have numbers": "Passwort muss Zahlen enthalten",
    "Password must have at least 8 characters": "Passwort muss mindestens 8 Zeichen lang sein",
    "Password must have upper case letters": "Passwort muss Großbuchstaben enthalten",
    "Incorrect username or password.": "Falscher Username oder Passwort",
    "Cannot reset password for the user as there is no registered/verified email or phone_number": "Passwort kann ohne verifizierte E-Mailadresse nicht zurückgesetzt werden.",
    "Plan is required": "Paket ist ein Pflichtfeld",
    "Add item": "Eintrag auswählen",
    "Cancel": "Abbrechen",
    "Add": "Hinzufügen",
  },
});

const App = () => {
  const components = {
    Header: () => {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
        </View >
      );
    },
    Footer: () => {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large} style={footerStyle}>
          <Link href="https://avaneo.io#impressum" color="#000000" isExternal={true}>
            Impressum
          </Link>
          <Text>Version: {packageJson.version}</Text>
        </View>
      );
    },
    SignIn: {
      Header: () => {
        const { tokens } = useTheme();

        return (
          <View>
            <View textAlign="center">
              <Image
                alt="Logo"
                src={logo}
                objectFit="initial"
                objectPosition="50% 50%"
                backgroundColor="initial"
                height="75%"
                width="8em"
                opacity="100%"
                marginTop="1em"
              />
            </View>
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={3}
            >
              Wo ist mein Plakat? - Konsole
            </Heading>
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={4}
            >
              Login
            </Heading>
          </View>
        );
      }
    },
    SignUp: {
      Header: () => {
        const { tokens } = useTheme();

        return (
          <View>
            <View textAlign="center">
              <Image
                alt="Logo"
                src={logo}
                objectFit="initial"
                objectPosition="50% 50%"
                backgroundColor="initial"
                height="75%"
                width="8em"
                opacity="100%"
                marginTop="1em"
              />
            </View>
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={3}
            >
              Wo ist mein Plakat? - Konsole
            </Heading>
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={4}
            >
              Registrierung
            </Heading>
          </View>
        );
      },
      FormFields: () => {
        return (
          <Authenticator.SignUp.FormFields />
        );
      },
    }
  }

  return (
    <Authenticator initialAuthState="signIn" components={components} hideSignUp={false}>
      <Router>
        <AuthenticatedApp />
      </Router>
    </Authenticator>
  );
}

const footerStyle = {
  fontSize: "12px",
}

export default App;