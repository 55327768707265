import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
    Button,
    Flex,
    Heading,
    Loader,
    Message,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Text,
    useTheme,
} from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';

import moment from 'moment'
import 'moment/locale/de'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { listOrders, listCompanies } from './graphql/queries';
import { createOrder } from './graphql/mutations';

import { fetchUserGroups, getNumberOfValidLicenses, getNumberOfActiveEmployees } from './Common'

import OrderCreateForm from './ui-components/OrderCreateForm'

moment.locale('de')
I18n.putVocabularies(translations);
I18n.setLanguage('de');

I18n.putVocabularies({
    de: {
        "Plan is required": "Paket ist ein Pflichtfeld",
        "Add item": "Eintrag auswählen",
        "Cancel": "Abbrechen",
        "Add": "Hinzufügen",
    },
});

const OrdersTab = () => {
    const [orders, setOrders] = useState([]);
    const [isPurchaseModalOpen, setPurchaseModalOpen] = useState(false);
    const [isPriceMessageShown, setPriceMessageShown] = useState(false);
    const [priceMessage, setPriceMessage] = useState({
        heading: "",
        content: "",
        type: "info",
    });
    const [usedLicenses, setUsedLicenses] = useState(0);
    const [numberOfValidLicenses, setNumberOfValidLicenses] = useState(0);


    const { tokens } = useTheme();

    useEffect(() => {
        fetchOrders();
        calculateUsedLicenses();
    }, []);

    const calculateUsedLicenses = async () => {
        const numberOfValidLicenses = await getNumberOfValidLicenses();
        const numberOfEmployees = await getNumberOfActiveEmployees();

        setNumberOfValidLicenses(numberOfValidLicenses);

        if (numberOfValidLicenses === 0) {
            setUsedLicenses(100);
        } else {
            setUsedLicenses(Math.round((numberOfEmployees / numberOfValidLicenses) * 100));
        }
    }

    const fetchOrders = async () => {
        try {
            const orderData = await API.graphql(graphqlOperation(listOrders));
            setOrders(orderData.data.listOrders.items);
        } catch (err) {
            console.error("Error fetching groups:", err);
        }
    };

    const getEndDate = (planName, startDate) => {
        let endDate;
        if (planName === "1 Monat") {
            endDate = moment(startDate).add(1, "month")
        } else if (planName === "3 Monate") {
            endDate = moment(startDate).add(3, "month")
        } else if (planName === "6 Monate") {
            endDate = moment(startDate).add(6, "month")
        } else if (planName === "1 Jahr") {
            endDate = moment(startDate).add(1, "year")
        }
        return endDate;
    }

    return (
        <div className="group-tab">

            <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            >
                <Heading level={3}>Käufe</Heading>
                <Flex direction="row" justifyContent="space-evenly" alignItems="center" gap="2rem">
                    <Flex direction="row">
                        <Text fontWeight={800}>Lizenznutzung:</Text>
                        {numberOfValidLicenses > 0 ? <Loader variation="linear" percentage={usedLicenses} isDeterminate width="14em" /> : <Text>Keine aktiven Lizenzen</Text>}
                    </Flex>
                    <Button marginRight="1em" onClick={() => setPurchaseModalOpen(true)}>Lizenzen kaufen&nbsp;<FontAwesomeIcon icon={faShoppingCart} /></Button>
                </Flex>
            </Flex>
            <Modal isOpen={isPurchaseModalOpen}
                onRequestClose={() => setPurchaseModalOpen(false)}
                contentLabel="Lizenz kaufen"
                style={createModalStyles}
                ariaHideApp={false}>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                >
                    <Heading level={4}>Lizenzen kaufen</Heading>
                    <Button onClick={() => {
                        setPurchaseModalOpen(false);
                        setPriceMessageShown(false);
                    }}><FontAwesomeIcon icon={faX} /></Button>
                </Flex>

                <OrderCreateForm
                    onValidate={{
                        count: (value, validationResponse) => {
                            if (value <= 0) {
                                return {
                                    hasError: true,
                                    errorMessage: 'Anzahl muss positiv sein'
                                };
                            }
                            return validationResponse;
                        },
                        startDate: (value, validationResponse) => {
                            var currentDate = new Date();
                            currentDate.setHours(0, 0, 0, 0);
                            if (new Date(value) < currentDate) {
                                return {
                                    hasError: true,
                                    errorMessage: 'Datum darf nicht in der Vergangenheit liegen'
                                };
                            }
                            return validationResponse;
                        },
                    }}
                    onSubmit={async (fields) => {
                        const cognitoGroups = await fetchUserGroups();
                        const cognitoGroup = cognitoGroups[0];
                        const companyData = await API.graphql(graphqlOperation(listCompanies));
                        const companies = companyData.data.listCompanies.items;
                        let company;
                        for (const c of companies) {
                            if (c.alias === cognitoGroup) {
                                company = c;
                                break;
                            }
                        }
                        const endDate = moment(getEndDate(fields.plan.name, fields.startDate)).format("YYYY-MM-DD");
                        const createOrderInput = {
                            companyAlias: cognitoGroup,
                            startDate: fields.startDate,
                            endDate: endDate,
                            orderCompanyId: company.id,
                            orderPlanId: fields.plan.id,
                            count: fields.count,
                            price: fields.plan.price * fields.count,
                            monthlyPrice: fields.plan.monthlyPrice,
                        }

                        await API.graphql(
                            graphqlOperation(createOrder, { input: createOrderInput })
                        );

                        calculateUsedLicenses();
                        await fetchOrders();
                        setPurchaseModalOpen(false);
                        setPriceMessageShown(false);
                    }}
                    onChange={async (fields) => {
                        if (fields.plan && fields.startDate) {
                            const endDate = getEndDate(fields.plan.name, fields.startDate);

                            setPriceMessage({
                                heading: "Informationen zum Kauf",
                                content: `Gesamtkosten (inkl. 19% MwSt): ${fields.count * fields.plan.price}€, Laufzeit bis ${moment(endDate).format("l")}`,
                                type: "info",
                            });
                            setPriceMessageShown(true);
                        }
                    }}
                />
            </Modal>
            {isPriceMessageShown && <Message
                variation="filled"
                colorTheme={priceMessage.type}
                heading={priceMessage.heading}
                style={priceStyles}>
                {priceMessage.content}
            </Message>
            }
            <Table marginTop={tokens.space.xl} variation="striped">
                <TableHead>
                    <TableRow>
                        <TableCell as="th">Paket</TableCell>
                        <TableCell as="th">Anzahl</TableCell>
                        <TableCell as="th">Monatlicher Preis</TableCell>
                        <TableCell as="th">Gesamtpreis</TableCell>
                        <TableCell as="th">Startdatum</TableCell>
                        <TableCell as="th">Enddatum</TableCell>
                        <TableCell as="th">Kaufdatum</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map(order => (
                        <TableRow key={order.id}>
                            <TableCell>{order.plan.name}</TableCell>
                            <TableCell>{order.count}</TableCell>
                            <TableCell>{order.monthlyPrice} €</TableCell>
                            <TableCell>{order.price} €</TableCell>
                            <TableCell>{moment(order.startDate).format("l")}</TableCell>
                            <TableCell>{moment(order.endDate).format("l")}</TableCell>
                            <TableCell>{moment(order.createdAt).format("l")}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

const createModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
};

const priceStyles = {
    position: "absolute",
    bottom: "50px",
    right: "100px",
    "zIndex": "1050",
}

export default OrdersTab;
